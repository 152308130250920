export function saveFileFromBlob(blob: Blob, fileName: string) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  // the filename you want
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
}

export function saveFileFromJson(
  json: { [key: string]: any },
  fileName: string
) {
  const blob = new Blob([JSON.stringify(json, null, 2)], {
    type: "application/json",
  }); // Convert JSON to Blob
  saveFileFromBlob(blob, fileName); // Use the existing function to save the file
}
