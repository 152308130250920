import { useRef, useState } from "react";
import {
  ChartBarSquareIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { DebugPanel } from "../../../completionSession/DebugPanel";
import ExportButton from "./ExportButton";
import { CheckIcon } from "@heroicons/react/20/solid";

export function SurveyWorkspaceHeader({
  surveyId,
  title,
  height,
  setTitle,
  isDebugAvailable,
  showSubmit,
  onNext,
}: {
  surveyId: string;
  setTitle(title: string): void;
  title: string;
  height: number;
  isDebugAvailable: boolean;
  showSubmit: boolean;
  onNext: () => void;
}) {
  const [showDebug, setShowDebug] = useState(false);
  const titleRef = useRef<HTMLInputElement>(null);
  function handleSubmit(e: React.FormEvent) {
    e.stopPropagation();
    titleRef.current?.blur();
  }

  return (
    <div style={{ height }} className="border-b flex flex-row">
      <div className="flex flex-row justify-content items-center pl-2">
        <Link
          to="/drafter/surveys"
          className="rounded text-gray-500 flex flex-row justify-center items-center p-1 hover:bg-gray-200"
        >
          <DocumentTextIcon className="w-5 h-5" />
        </Link>
      </div>
      <form
        onSubmit={handleSubmit}
        className="h-full flex flex-col justify-center flex-1"
      >
        <input
          type="text"
          name="title"
          value={title}
          ref={titleRef}
          style={{ minWidth: 400, width: 600 }}
          className="rounded inline-block hover:bg-gray-100 outline-none border-none bg-transparent font-medium text-sm px-2 py-1 placeholder:text-gray-400 focus:ring-none ring-none"
          placeholder="Untitled Survey"
          onChange={(e) => setTitle(e.target.value)}
        />
      </form>
      <div className="flex flex-row items-center pr-12 space-x-2">
        {isDebugAvailable ? (
          <a
            href={`https://app.plucky.ai/session-redirect/${surveyId}`}
            target="_blank"
            className="w-7 h-7 flex flex-row justify-center items-center hover:bg-gray-200 rounded"
          >
            <ChartBarSquareIcon className="w-5 h-5 text-gray-700" />
          </a>
        ) : null}

        {showSubmit ? (
          <SurveySubmitButton surveyId={surveyId} onNext={onNext} />
        ) : null}
      </div>
      {isDebugAvailable ? (
        <DebugPanel
          show={showDebug}
          setShow={setShowDebug}
          surveyId={surveyId}
        />
      ) : null}
    </div>
  );
}

function SurveySubmitButton({
  surveyId,
  onNext,
}: {
  surveyId: string;
  onNext: () => void;
}) {
  return (
    <button
      type="button"
      disabled={false}
      onClick={onNext}
      className="inline-flex items-center rounded-md bg-sky-800 disabled:bg-gray-300 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700"
    >
      Next
    </button>
  );
}

function isInternalEmail(email: string) {
  return (
    email.includes("@incquery.com") ||
    email.includes("@inc-query.com") ||
    email.includes("@plucky.ai")
  );
}
