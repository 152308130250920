import { Disclosure } from "@headlessui/react";
import Wordmark from "./Wordmark";
import { Link } from "react-router-dom";
import { UserIcon } from "@heroicons/react/20/solid";
import { useState } from "react";

const links = [
  {
    name: "Home",
    to: "https://app.incquery.com",
  },
  {
    name: "Knowledge Base",
    to: "https://www.incquery.com/knowledge",
  },
  {
    name: "Data Analysis",
    to: "https://www.incquery.com/analytics/overview",
  },
];

export default function TopNav({ user }: { user: any }) {
  const [loading, setLoading] = useState(false);
  const isInternal =
    user.email &&
    (user.email.includes("@incquery.com") ||
      user.email.includes("@plucky.ai") ||
      user.email.includes("@inc-query.com"));

  return (
    <Disclosure
      as="nav"
      className="text-white shadow-md"
      style={{ backgroundColor: "#09415A" }}
    >
      {({ open }) => (
        <>
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex h-14 justify-between">
              <div className="flex">
                <div className="flex flex-shrink-0 items-center mt-1">
                  <a
                    href="https://app.incquery.com"
                    style={{ transform: `scale(45%)` }}
                  >
                    <Wordmark />
                  </a>
                </div>
                <div className="sm:ml-6 sm:flex sm:space-x-1 flex-1">
                  {links.map((link) => (
                    <a
                      href={link.to}
                      key={link.name}
                      style={{
                        fontFamily: "Roboto, Helvetica, Arial, sans",
                      }}
                      className="uppercase inline-flex items-center border-b-2 border-transparent px-6 pt-1 text-sm font-medium hover:bg-nav-hover"
                    >
                      {link.name}
                    </a>
                  ))}
                  <Link
                    to={"/drafter"}
                    style={{
                      fontFamily: "Roboto, Helvetica, Arial, sans",
                    }}
                    className="uppercase inline-flex items-center border-b-2 border-transparent px-6 pt-1 text-sm font-medium hover:bg-nav-hover border-white"
                  >
                    AI Drafter
                  </Link>
                </div>
              </div>
              <div className="flex items-center">
                <a
                  className="bg-gray-200 rounded-full w-8 h-8 hover:bg-gray-100 hover:cursor"
                  href="http://app.incquery.com"
                >
                  <UserIcon
                    className="w-8 h-8 text-gray-500 relative"
                    style={{ top: 4 }}
                  />
                </a>
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
}
