import axios from "axios";

export async function fetchOne(id: string): Promise<any> {
  const { data } = await axios.get(`/api/surveys/${id}`);
  return data;
}

export async function create(body: any): Promise<any> {
  const { data } = await axios.post(`/api/surveys`, body);
  return data;
}

export async function update(id: string, body: any): Promise<any> {
  const { data } = await axios.patch(`/api/surveys/${id}`, body);
  return data;
}

export async function fetchAll(params: any): Promise<any> {
  const { data } = await axios.get(`/api/surveys`, {
    params,
  });
  return data;
}
export async function archive(id: string): Promise<any> {
  const { data } = await axios.post(`/api/surveys/${id}/archive`);
  return data;
}

export async function download(id: string, force = false): Promise<any> {
  const { data } = await axios.post(
    `/api/surveys/${id}/download`,
    {
      force,
    },
    {
      timeout: 240000,
    }
  );
  return data;
}
