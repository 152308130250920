import {
  ArrowDownCircleIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";
import { useParams } from "react-router-dom";
import { Redirect } from "../features/nav/Redirect";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { saveFileFromJson } from "../utils/download";
import { download } from "../features/survey/surveyAPI";
import { handleError } from "../features/errors/errorUtils";
import { CenteredSpinner } from "../components/Spinner";

type DownloadState = "notStarted" | "started" | "done" | "error";

export default function SurveyExportView() {
  const [downloadState, setDownloadState] =
    useState<DownloadState>("notStarted");
  const { surveyId } = useParams();
  async function startDownload(force = false) {
    try {
      if (downloadState === "started" || !surveyId) return;
      setDownloadState("started");
      const json = await download(surveyId, force);
      saveFileFromJson(json, `survey-export-${surveyId}.json`);
      setDownloadState("done");
    } catch (err) {
      handleError(err);
      setDownloadState("error");
    }
  }
  if (!surveyId) return <Redirect to="/drafter/surveys" />;
  return (
    <div className="flex-1 max-h-screen flex flex-col justify-center items-center">
      <div className="py-24 max-w-md">
        <div className="mx-auto flex h-12 w-12 items-center justify-center">
          <ArrowDownCircleIcon
            aria-hidden="true"
            className="h-12 w-12 text-gray-700"
          />
        </div>
        <div className="">
          <h3 className="text-base font-semibold text-gray-900 text-center">
            Let's get ready for programming.
          </h3>
          <div className="mt-2">
            <table>
              <tbody>
                <tr>
                  <td className="text-sm w-64">1. Download survey export</td>
                  <td className="py-2 pl-6">
                    <DownloadButton
                      state={downloadState}
                      startDownload={startDownload}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-sm w-64">
                    2. Go to your IncQuery dashboard
                  </td>
                  <td className="py-2 pl-6">
                    <a
                      href="https://app.incquery.com/s"
                      target="_blank"
                      className="block text-center w-full rounded bg-gray-100 px-6 py-3 text-sm hover:bg-gray-200"
                    >
                      <ArrowTopRightOnSquareIcon className="w-5 h-5 inline-block text-gray-600 mr-2" />
                      Open
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="text-sm w-64">3. Click "Create Survey"</td>
                  <td className="py-2 pl-6">
                    <img
                      src="/drafter/images/create-survey.png"
                      width={300}
                      className="rounded border"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-sm w-64">4. Enter survey details</td>
                  <td className="py-2 pl-6">
                    <img
                      src="/drafter/images/survey-details.png"
                      width={300}
                      className="rounded border"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-sm w-64">5. Upload your survey export</td>
                  <td className="py-2 pl-6">
                    <img
                      src="/drafter/images/upload-json.png"
                      width={300}
                      className="rounded border"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

function DownloadButton({
  state,
  startDownload,
}: {
  state: DownloadState;
  startDownload: (force: boolean) => void;
}) {
  if (state === "done") {
    return (
      <p className="text-sm italic text-gray-700">
        Done! Check your downloads folder.{" "}
        <button
          type="button"
          className="font-bold hover:underline"
          onClick={() => startDownload(true)}
        >
          Retry
        </button>
      </p>
    );
  }
  if (state === "error") {
    return (
      <div className="text-sm">
        <p className="text-red-700 italic">
          Sorry, we had trouble downloading your file. Please retry or contact
          your IncQuery Survey Director for assistance.
        </p>
        <button
          type="button"
          className="font-bold hover:underline"
          onClick={() => startDownload(true)}
        >
          Retry
        </button>
      </div>
    );
  }
  if (state === "started") {
    return (
      <div>
        <CenteredSpinner size="sm" />
        <div className="pt-1 text-sm italic">
          Preparing file. (This may take a moment.) Download will start
          automatically.
        </div>
      </div>
    );
  }
  return (
    <button
      type="button"
      onClick={() => startDownload(false)}
      className="block text-center w-full rounded bg-gray-100 px-6 py-3 text-sm hover:bg-gray-200"
    >
      <ArrowDownTrayIcon className="w-5 h-5 inline-block text-gray-600 mr-2" />
      Download
    </button>
  );
}
