import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import {
  QuestionDraftData,
  SectionDraftData,
  SurveyData,
  SurveyDraftData,
} from "../../survey.types";
import { useAppDispatch } from "../../../../app/hooks";
import { updateSurvey } from "../../surveySlice";
import { handleError } from "../../../errors/errorUtils";
import { throwIfError } from "../../../../utils/error";
import { uuidv4 } from "../../../../utils/uuid";
import { getSectionStartingNumbers } from "../utils/utils";
import _ from "lodash";
import invariant from "tiny-invariant";

export function useSurvey(savedSurvey: SurveyData) {
  const [survey, setSurvey] = useState<SurveyData>(savedSurvey);
  const [saved, setSaved] = useState(true);
  const timerRef = useRef<NodeJS.Timeout>();
  const dispatch = useAppDispatch();

  async function saveSurvey(survey: SurveyDraftData) {
    await throwIfError(dispatch(updateSurvey(survey)));
  }

  useEffect(() => {
    if (saved) return;
    window.onbeforeunload = () => true;
    return () => {
      window.onbeforeunload = null;
    };
  }, [saved]);

  useEffect(() => {
    setSurvey((survey) => {
      const newSurvey = { ...survey };
      if (
        survey.sections.length === 0 ||
        survey.sections[0].title !== "Screener"
      ) {
        newSurvey.sections = [
          {
            id: uuidv4(),
            title: "Screener",
            objectives: "",
            questions: [
              {
                id: uuidv4(),
                text: "",
                previousText: "",
                accepted: true,
              },
            ],
            steps: [],
          },
          ...survey.sections,
        ];
      } else if (newSurvey.sections[0].questions.length === 0) {
        newSurvey.sections = newSurvey.sections.map((section, s) => {
          if (s === 0) {
            return {
              ...section,
              questions: [
                {
                  id: uuidv4(),
                  text: "",
                  previousText: "",
                  accepted: true,
                },
              ],
            };
          }
          return section;
        });
      }
      return newSurvey;
    });
  }, [survey.sections]);

  useEffect(() => {
    if (_.isEqual(savedSurvey, survey)) return;
    setSaved(false);
    timerRef.current = setTimeout(async () => {
      try {
        await saveSurvey(survey);
        setSaved(true);
      } catch (err) {
        handleError(err);
      }
    }, 750);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [survey]);
  const invalidQuestionIds = survey.sections
    .flatMap((section) => section.questions)
    .filter((question) => !question.accepted)
    .map((question) => question.id);

  return {
    survey,
    setSurvey,
    saveSurvey,
    invalidQuestionIds,
    isValid: invalidQuestionIds.length === 0,
  };
}

export function useSurveySetters(
  survey: SurveyData,
  setSurvey: Dispatch<SetStateAction<SurveyData>>
) {
  const questionCount = survey.sections.reduce((acc, section) => {
    return section.questions.length + acc;
  }, 0);

  function clearSurveySections() {
    setSurvey((survey) => ({
      ...survey,
      sections: [],
    }));
  }
  function addSection(
    i?: number,
    data?: {
      title?: string;
      objectives?: string;
    }
  ) {
    data = data || {};
    const insertAt = i === undefined ? survey.sections.length : i;
    setSurvey((survey) => {
      return {
        ...survey,
        sections: [
          ...survey.sections.slice(0, insertAt),
          {
            id: uuidv4(),
            title: "",
            objectives: "",
            questions: [],
            steps: [],
            ...data,
          },
          ...survey.sections.slice(insertAt),
        ],
      };
    });
  }
  function removeSection(s: number) {
    setSurvey((survey) => {
      const sections = survey.sections.filter((_, i) => i !== s);
      return { ...survey, sections };
    });
  }
  function setSection(s: number, section: SectionDraftData) {
    setSurvey((survey) => {
      const sections = [...survey.sections];
      const existingSection = sections[s];
      sections[s] = {
        id: existingSection?.id || uuidv4(),
        ...section,
        questions: mergeQuestions(
          existingSection?.questions || [],
          section.questions || []
        ),
      };
      return { ...survey, sections };
    });
  }

  function mergeQuestions(
    oldQuestions: QuestionDraftData[],
    newQuestions: QuestionDraftData[]
  ) {
    return newQuestions.map((question, i) => {
      const oldQuestion = oldQuestions[i];
      if (oldQuestion) {
        return {
          ...question,
          id: oldQuestion?.id || uuidv4(),
        };
      }
      return {
        ...question,
        id: uuidv4(),
      };
    });
  }

  function addQuestion(sectionId: string, i: number, content?: any) {
    const id = uuidv4();
    setSurvey((survey) => {
      const sections = survey.sections.map((section) => {
        if (section.id === sectionId) {
          const questions = section.questions.concat([]);
          questions.splice(i, 0, {
            id,
            text: content || "",
            previousText: "",
            accepted: true,
          });
          return { ...section, questions };
        }
        return section;
      });
      return { ...survey, sections };
    });
    return id;
  }

  function removeQuestion(sectionId: string, questionId: string) {
    setSurvey((survey) => {
      const sections = survey.sections.map((section) => {
        if (section.id === sectionId) {
          const questions = section.questions.filter(
            (question) => question.id !== questionId
          );
          return { ...section, questions };
        }
        return section;
      });
      return { ...survey, sections };
    });
  }

  function setQuestion(
    sectionId: string,
    questionId: string,
    content: string,
    options?: {
      accepted?: boolean;
      previousText?: string;
    }
  ) {
    options = options || {};
    setSurvey((survey) => {
      const sections = survey.sections.map((section) => {
        if (section.id === sectionId) {
          const questions = section.questions.map((question) => {
            if (question.id === questionId) {
              return {
                ...question,
                text: content,
                accepted:
                  options.accepted === undefined
                    ? question.accepted
                    : options.accepted,
                previousText: options.previousText || "",
              };
            }
            return question;
          });
          return { ...section, questions };
        }
        return section;
      });
      return { ...survey, sections };
    });
  }

  function moveQuestion(questionId: string, sectionId: string, index: number) {
    setSurvey((survey) => {
      const question = survey.sections
        .flatMap((section) => section.questions)
        .find((q) => q.id === questionId);
      invariant(question);
      const newSections = survey.sections.map((section) => {
        let newQuestions = [...section.questions];
        if (section.id === sectionId) {
          newQuestions.splice(index, 0, question);
        }
        newQuestions = newQuestions.filter(
          (question, q) =>
            question.id !== questionId ||
            (question.id === question.id && q === index)
        );

        return {
          ...section,
          questions: newQuestions,
        };
      });
      return {
        ...survey,
        sections: newSections,
      };
    });
  }
  function setStep(sectionId: string, stepId: string, content: string) {
    setSurvey((survey) => {
      const sections = survey.sections.map((section) => {
        if (section.id === sectionId) {
          const steps = section.steps.map((step) => {
            if (step.id === stepId) {
              return {
                ...step,
                text: content,
              };
            }
            return step;
          });
          return { ...section, steps };
        }
        return section;
      });
      return { ...survey, sections };
    });
  }
  function addStep(sectionId: string, i: number, content?: string) {
    const id = uuidv4();
    setSurvey((survey) => {
      const sections = survey.sections.map((section) => {
        if (section.id === sectionId) {
          const steps = section.steps.concat([]);
          steps.splice(i, 0, {
            id,
            text: content || "",
          });
          return { ...section, steps };
        }
        return section;
      });
      return { ...survey, sections };
    });
    return id;
  }

  function removeStep(sectionId: string, stepId: string) {
    setSurvey((survey) => {
      const sections = survey.sections.map((section) => {
        if (section.id === sectionId) {
          const steps = section.steps.filter(
            (question) => question.id !== stepId
          );
          return { ...section, steps };
        }
        return section;
      });
      return { ...survey, sections };
    });
  }
  function getSectionById(sectionId: string) {
    const section = survey.sections.find((s) => s.id === sectionId);
    if (!section)
      throw new Error(`Could not find section with id ${sectionId}`);
    return section;
  }

  function clearQuestions() {
    setSurvey((survey) => {
      const sections = survey.sections.map((section) => {
        return {
          ...section,
          questions: [],
        };
      });
      return { ...survey, sections };
    });
  }

  function setObjectives(objectives: string) {
    setSurvey((survey) => ({
      ...survey,
      objectives,
    }));
  }
  const questionCounts = survey.sections.map(
    (section) => section.questions.length
  );
  const stepCounts = survey.sections.map((section) => section.steps.length);

  const sectionStartingNumbers = getSectionStartingNumbers(questionCounts);
  const sectionStepStartingNumbers = getSectionStartingNumbers(stepCounts);

  return {
    addSection,
    setSection,
    setAudience: (audience: string) =>
      setSurvey((survey) => ({ ...survey, audience })),
    setTitle: (title: string) => setSurvey((survey) => ({ ...survey, title })),
    clearSurveySections,
    removeSection,
    clearQuestions,
    questionCount,
    stepCount: survey.sections.reduce(
      (count, section) => count + section.steps.length,
      0
    ),
    setObjectives,
    sectionStartingNumbers,
    sectionStepStartingNumbers,
    getSectionById,
    addQuestion,
    removeQuestion,
    moveQuestion,
    setQuestion,
    setStep,
    addStep,
    removeStep,
    setPlan: (plan: string) => setSurvey((survey) => ({ ...survey, plan })),
    setRespondentType: (respondentType: string) =>
      setSurvey((survey) => ({
        ...survey,
        respondentType: respondentType || null,
      })),
    setLocation: (location: string) =>
      setSurvey((survey) => ({ ...survey, location })),
  };
}
